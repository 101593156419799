import React from "react"

export default function AcademyLogo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-2 -2 318 318">
      <circle
        cx="156.68"
        cy="156.68"
        r="156.56"
        className="stroke-current stroke-2"
        fill="none"
      ></circle>
      <path
        d="M102.64 273.19l-1.66 5.02h-2.13l5.42-15.95h2.48l5.44 15.95h-2.2l-1.7-5.02h-5.66zm5.23-1.61l-1.56-4.59c-.36-1.04-.59-1.99-.83-2.91h-.05c-.24.95-.5 1.92-.8 2.89l-1.56 4.61h4.8zM130.07 277.68c-.76.38-2.27.76-4.21.76-4.5 0-7.88-2.84-7.88-8.07s3.38-8.38 8.33-8.38c1.99 0 3.24.43 3.79.71l-.5 1.68c-.78-.38-1.89-.66-3.22-.66-3.74 0-6.22 2.39-6.22 6.58 0 3.9 2.25 6.41 6.13 6.41 1.25 0 2.53-.26 3.36-.66l.43 1.63zM140.05 273.19l-1.66 5.02h-2.13l5.42-15.95h2.49l5.44 15.95h-2.2l-1.7-5.02h-5.66zm5.23-1.61l-1.56-4.59c-.35-1.04-.59-1.99-.83-2.91h-.05c-.24.95-.5 1.92-.8 2.89l-1.56 4.61h4.8zM156.68 262.46c1.25-.19 2.75-.33 4.38-.33 2.96 0 5.07.69 6.46 1.99 1.42 1.3 2.25 3.15 2.25 5.73s-.8 4.73-2.29 6.2c-1.49 1.49-3.95 2.29-7.05 2.29-1.47 0-2.7-.07-3.74-.19v-15.69zm2.06 14.11c.52.09 1.28.12 2.08.12 4.4 0 6.79-2.46 6.79-6.77.02-3.76-2.11-6.15-6.46-6.15-1.06 0-1.87.09-2.41.21v12.59zM185.43 270.72h-6.2v5.75h6.91v1.73h-8.97v-15.95h8.61v1.73h-6.56v5.04h6.2v1.7zM206.99 271.2c-.12-2.22-.26-4.9-.24-6.89h-.07c-.54 1.87-1.21 3.86-2.01 6.06l-2.82 7.74h-1.56l-2.58-7.6c-.76-2.25-1.4-4.31-1.85-6.2h-.05c-.05 1.99-.17 4.66-.31 7.05l-.43 6.84h-1.96l1.11-15.95h2.63l2.72 7.71c.66 1.96 1.21 3.72 1.61 5.37h.07c.4-1.61.97-3.36 1.68-5.37l2.84-7.71h2.63l.99 15.95h-2.01l-.4-7zM220.85 278.2v-6.77l-5.04-9.18h2.34l2.25 4.4c.62 1.21 1.09 2.18 1.58 3.29h.05c.45-1.04.99-2.08 1.61-3.29l2.29-4.4h2.34l-5.35 9.16v6.79h-2.08z"
        className="fill-current"
      ></path>
      <g>
        <path
          d="M169.12 227.83c-12.19 10.41-27.05 16.5-44.59 18.28-9.52.89-18.43.23-26.75-2.01-8.32-2.23-15.54-5.94-21.62-11.15-6.1-5.2-10.78-11.66-14.05-19.39-3.27-7.73-4.31-16.64-3.12-26.75.89-8.92 3.41-16.5 7.58-22.74a68.785 68.785 0 0115.16-16.28c5.94-4.6 12.63-8.47 20.07-11.59 7.43-3.12 15.01-5.87 22.74-8.25 12.78-4.16 22.59-8.09 29.43-11.82 6.83-3.71 11.88-7.2 15.16-10.48-4.14 4.46-8.72 8.69-13.75 12.71-5.02 4.01-9.6 8.77-13.74 14.27-4.14 5.5-7.61 12.49-10.42 20.96-2.81 8.47-4.21 19.55-4.21 33.22 0 11.3 1.48 20.29 4.43 26.97 2.95 6.69 6.57 11.59 10.85 14.72 4.28 3.12 8.87 4.53 13.75 4.24 4.88-.29 9.23-1.93 13.08-4.91z"
          className="fill-current"
        ></path>
        <path
          d="M243.39 213.12v21.85c0 3.27 1.04 5.95 3.12 8.03 2.07 2.08 4.75 3.12 8.03 3.12h-49.7c-12.11 0-20.46-2.97-25.03-8.92-4.58-5.94-6.87-16.05-6.87-30.32V78.02c0-13.67-1.06-25.48-3.17-35.45-2.11-9.96-6.18-15.23-12.21-15.83 11.86 0 22.97 1.56 33.35 4.68 10.38 3.12 19.43 7.43 27.13 12.93 7.71 5.5 13.86 11.97 18.45 19.4 4.6 7.43 6.9 15.31 6.9 23.63v125.74zM144.66 27.19c-6.41.6-11.62 2.61-15.64 6.02-4.01 3.42-6.47 7.06-7.35 10.92-.89 4.17 0 7.66 2.67 10.48 2.68 2.83 5.2 5.43 7.58 7.8 3.27 3.27 5.8 6.99 7.58 11.15 1.78 4.16 2.68 8.77 2.68 13.82 0 9.81-3.42 18.13-10.26 24.97s-15.16 10.26-24.97 10.26-18.13-3.41-24.97-10.26c-6.84-6.83-10.26-15.16-10.26-24.97 0-7.43 1.85-14.56 5.56-21.4 3.71-6.83 8.82-12.93 15.34-18.28 6.52-5.35 14.23-9.81 23.13-13.38 8.89-3.56 18.53-5.94 28.91-7.13z"
          className="fill-current"
        ></path>
      </g>
    </svg>
  )
}
{
  /* <defs><style>.b{fill:#231f20;}.c{fill:none;stroke:#231f20;stroke-miterlimit:10;stroke-width:.25px;}</style></defs> */
}
