import React from "react"

export default function LinkedIn() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 2490 2490"
    >
      <path
        d="M2306 5H184C82.42 5 0 84.91 0 183.37v2133.32C0 2415.15 82.43 2495 184 2495h2122c101.65 0 184-79.86 184-178.34V183.37C2490 84.91 2407.62 5 2306 5zM384.08 2088.8v-1121h372.61v1121zm186.38-1274H568c-125 0-205.9-86.08-205.9-193.66 0-110 83.29-193.69 210.81-193.69s205.89 83.67 208.32 193.7c.02 107.54-80.86 193.62-210.77 193.62zm1529.82 1274h-372.57v-599.64c0-150.72-54-253.51-188.74-253.51-103 0-164.27 69.32-191.19 136.29-9.84 23.9-12.27 57.45-12.27 90.91v626H962.93s4.89-1015.84 0-1121h372.58v158.68c49.53-76.37 138.18-185 335.81-185 245.14 0 428.94 160.23 428.94 504.54z"
        className="cls-1"
        transform="translate(0 -5)"
      ></path>
    </svg>
  )
}
