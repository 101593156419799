import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import WhiteButton from "../common/WhiteButton"

export default function KontaktaOss({ kontaktaOssGruppbild }) {
  const { gruppbildtext, knapptext, gruppbild } = kontaktaOssGruppbild
  return (
    <div>
      <div className="h-[50vh] md:h-screen  z-0 relative bg-black">
        <div className="relative h-full">
          <div className="relative h-full">
            <GatsbyImage
              image={getImage(gruppbild.localFile.childImageSharp)}
              objectFit="cover"
              className="h-full w-full opacity-100"
              alt="Kontakta oss"
            />
          </div>
          <div className="hidden md:flex absolute top-0 w-full h-full  items-end justify-end bg-gradient-to-t from-fourth via-transparent ">
            <div className="text-white flex flex-col md:flex-row items-center gap-2 md:gap-8 p-4 md:p-8">
              <div>
                <p className="font-bold">Maneki Stockholm</p>
              </div>
              <div className="text-center md:text-left px-8 md:px-0">
                <p>{gruppbildtext}</p>
              </div>
              <div>
                <a href="mailto:info@maneki.se">
                  {" "}
                  <WhiteButton text={knapptext} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="md:hidden w-full h-full flex items-end justify-end bg-fourth">
        <div className="text-white flex flex-col md:flex-row items-center gap-8 md:gap-8 p-8 md:p-8">
          <div>
            <p className="font-bold">Maneki Stockholm</p>
          </div>
          <div className="text-center md:text-left px-8 md:px-0">
            <p>{gruppbildtext}</p>
          </div>
          <div>
            <a href="mailto:info@maneki.se">
              {" "}
              <WhiteButton text={knapptext} />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
