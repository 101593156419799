import React from "react"

export default function Partners({ samarbetspartners }) {
  const { beskrivning, partners } = samarbetspartners

  return (
    <div id="partners" className="bg-primary">
      <div className="container max-w-6xl w-full mx-auto ">
        <div className="grid grid-cols-1   gap-8 md:gap-16">
          <div className="flex flex-col gap-4 md:gap-8">
            <h2>Ett samarbete för hållbarhet </h2>
            <div className="wp-content">
              <div dangerouslySetInnerHTML={{ __html: beskrivning }} />
            </div>
          </div>
          <div className="flex flex-col gap-4 md:gap-8 justify-center md:justify-center items-center  text-white">
            {partners.map((partner, i) => {
              return <Partner key={i} partner={partner} />
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

function Partner({ partner }) {
  const { logga } = partner

  return (
    <>
      <div className="w-48 md:w-72">
        <img src={logga.localFile.publicURL} alt="Logga" />
      </div>
    </>
  )
}
