import React from "react"

export default function VideoBackground({ video }) {
  return (
    <section className="video-wrapper h-full w-full overflow-hidden cover object-cover ">
      <video
        autoPlay
        preload="none"
        playsInline
        muted
        loop
        className="object-cover max-h-auto inset-0 w-full h-full z-0 relative"
      >
        <source
          src="https://olleburl.in/maneki/wp-content/uploads/2022/03/2.mp4"
          type="video/mp4"
        />
      </video>
    </section>
  )
}
