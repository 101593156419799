import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export default function Map() {
  const html = `<div class="mapouter"><div class="gmap_canvas"><iframe width="" height="" id="gmap_canvas" src="https://maps.google.com/maps?q=Gustavslundsv%C3%A4gen%20133%20Bromma&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><br><style>.mapouter{position:relative;text-align:right;height:600px;width:100%;}</style><a href="https://www.embedgooglemap.net">using google map on website</a><style>.gmap_canvas {overflow:hidden;background:none!important;height:600px;width:100%;}</style></div></div>`
  const htmlStrangnas = `<div class="mapouter"><div class="gmap_canvas"><iframe width="" height="" id="gmap_canvas" src="https://maps.google.com/maps?q=Koppargr%C3%A4nd%203%20645%2030%20Str%C3%A4ngn%C3%A4s&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><br><style>.mapouter{position:relative;text-align:right;height:600px;width:100%;}</style><a href="https://www.embedgooglemap.net">using google map on website</a><style>.gmap_canvas {overflow:hidden;background:none!important;height:600px;width:100%;}</style></div></div>`
  const htmlEnkoping = `<div class="mapouter"><div class="gmap_canvas"><iframe width="" height="" id="gmap_canvas" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2016.227349045257!2d17.114453813462614!3d59.64586607466395!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465e51d82d26d9b9%3A0x30ffc5e997f4a50e!2sGarnisonsv%C3%A4gen%202%2C%20749%2040%20Enk%C3%B6ping!5e0!3m2!1sen!2sse!4v1702382810237!5m2!1sen!2sse" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><br><style>.mapouter{position:relative;text-align:right;height:600px;width:100%;}</style><a href="https://www.embedgooglemap.net">using google map on website</a><style>.gmap_canvas {overflow:hidden;background:none!important;height:600px;width:100%;}</style></div></div>`

  return (
    <div className="w-full h-full bg-fifth">
      <div className="flex flex-col md:flex-row w-full">
        <div
          className="grayscale mix-blend-overlay flex-1"
          dangerouslySetInnerHTML={{ __html: html }}
        />
        {/* <div
          className="grayscale mix-blend-overlay flex-1"
          dangerouslySetInnerHTML={{ __html: htmlStrangnas }}
        /> */}
        <div
          className="grayscale mix-blend-overlay flex-1"
          dangerouslySetInnerHTML={{ __html: htmlEnkoping }}
        />
      </div>
    </div>
  )
}
