import { func } from "prop-types"
import React from "react"

export default function Kontakt() {
  return (
    <div id="kontakt" className="bg-fifth">
      <div className="container max-w-6xl">
        <div className="flex flex-col gap-8">
          <h2 className="text-center">Kontakt</h2>
          <p>
            Du är alltid välkommen att kontakta oss. Ring, maila eller besök oss
            enligt uppgifterna nedan.
          </p>
          <div className="flex flex-col md:flex-row gap-8">
            {/* Stockholm */}
            <div className="flex flex-col gap-8 flex-1">
              <div className="font-bold">Stockholm</div>
              <div className="space-y-8">
                <div className="flex flex-row items-start justify-start md:items-center gap-2">
                  <div>
                    <Mail />
                  </div>
                  <div>Mejl: info@maneki.se</div>
                </div>
                <div className="flex flex-row items-center gap-2">
                  <div>
                    <Phone />
                  </div>
                  <div>Telefon: +46 200 45 65 28</div>
                </div>
                <div className="flex flex-row items-center gap-2">
                  <div>
                    <Location />
                  </div>
                  <div>Besök: Gustavslundsvägen 133, 10 tr, 167 51 Bromma</div>
                </div>
              </div>
            </div>
            {/* Strängnäs
            <div className="flex flex-col gap-8 flex-1">
              <div className="font-bold">Strängnäs</div>
              <div className="space-y-8 ">
                <div className="flex flex-row items-start justify-start md:items-center gap-2">
                  <div>
                    <Mail />
                  </div>
                  <div>Mejl: info@maneki.se</div>
                </div>
                <div className="flex flex-row items-center gap-2">
                  <div>
                    <Phone />
                  </div>
                  <div>Telefon: +46 200 45 65 28</div>
                </div>
                <div className="flex flex-row items-center gap-2">
                  <div>
                    <Location />
                  </div>
                  <div>
                    Besök: Koppargränd 3 <br />
                    645 30 Strängnäs
                  </div>
                </div>
              </div>
            </div> */}
            {/* Enköping */}
            <div className="flex flex-col gap-8 flex-1">
              <div className="font-bold">Enköping</div>
              <div className="space-y-8 ">
                <div className="flex flex-row items-start justify-start md:items-center gap-2">
                  <div>
                    <Mail />
                  </div>
                  <div>Mejl: info@maneki.se</div>
                </div>
                <div className="flex flex-row items-center gap-2">
                  <div>
                    <Phone />
                  </div>
                  <div>Telefon: +46 200 45 65 28</div>
                </div>
                <div className="flex flex-row items-center gap-2">
                  <div>
                    <Location />
                  </div>
                  <div>
                    Besök: Garnisonsvägen 2 <br />
                    749 40 Enköping
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function Mail() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-5 w-5"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
      <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
    </svg>
  )
}

function Phone() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-5 w-5"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
    </svg>
  )
}

function Location() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-5 w-5"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
        clipRule="evenodd"
      />
    </svg>
  )
}
