import React from "react"
import Link from "../common/Link"
import LinkedIn from "../common/LinkedIn"
import LogoOriginal from "../common/LogoOriginal"

export default function Footer({ sidfot }) {
  const gdpr = sidfot.gdpr.localFile.publicURL
  const { ovrigaDokument } = sidfot
  console.log(ovrigaDokument)
  return (
    <div className="bg-fourth text-white">
      <div className="max-w-screen-2xl mx-auto  p-8 md:py-16 flex flex-col items-center gap-8 md:gap-16">
        <div className="flex flex-col items-center justify-center">
          <div className="w-48 md:w-96">
            <LogoOriginal />
          </div>
        </div>
        <div className="w-12">
          <Link
            to="https://www.linkedin.com/company/maneki-revision-skatt-rådgivning/"
            title="LinkedIn"
          >
            <LinkedIn />
          </Link>
        </div>
      </div>
      <div className="col-span-2 text-center self-center p-8 space-y-4 text-base tracking-normal">
        <p>
          Copyright © 2022 Maneki Revision AB
          <br />
          Org nr 556459-3589
        </p>
        <p>
          Är du intresserad av att veta hur vi arbetar med GDPR så kan du göra
          det{" "}
          <Link className="underline" to={gdpr}>
            här
          </Link>
          .
        </p>
        <p>
          {ovrigaDokument.map((dokument, index) => (
            <React.Fragment>
              <Link to={dokument.fil.localFile.publicURL} className="underline">
                {dokument.titelOvrigafilerFil}
              </Link>
              {index < ovrigaDokument.length - 1 && ", "}
            </React.Fragment>
          ))}
        </p>
        <p className="text-sm">
          Concept and identity by{" "}
          <a href="https://www.luckynation.se" className="underline">
            Lucky Nation
          </a>
        </p>
      </div>
    </div>
  )
}
