import React from "react"
import WhiteButton from "../common/WhiteButton"

export default function Jobb({ jobb }) {
  const { beskrivningjobb, tjanster } = jobb
  return (
    <div id="jobb" className="bg-third">
      <div className="container max-w-6xl mx-auto space-y-8">
        <h2>Vill du vara en del av oss</h2>
        <div className="grid grid-cols-1 md:grid-cols-2  gap-4 md:gap-16">
          <div className="flex flex-col gap-4 md:gap-8">
            <div>
              <div className="wp-content">
                <div dangerouslySetInnerHTML={{ __html: beskrivningjobb }} />
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4 md:gap-8  ">
            {tjanster.map((tjanst, i) => {
              return <Tjanst key={i} tjanst={tjanst} />
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

function Tjanst({ tjanst }) {
  const { titel, information } = tjanst
  return (
    <>
      <h3>{titel}</h3>
      <div className="wp-content">
        <div dangerouslySetInnerHTML={{ __html: information }} />
      </div>
      <div className="underline">
        <a href="mailto:info@maneki.se">
          <WhiteButton text="Ansök nu" />
        </a>
      </div>
    </>
  )
}
