import React from "react"

export default function Blob() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 316.9 470.58"
    >
      <path
        d="M142 470.58q34.14-26.07 46.64-58t5.05-57.13q-8.39-26.9-32.17-35t-45.25-14Q51.85 288.55 19.89 232q-31-54.84-13.71-117.75T79.7 19.54Q134.21-11.24 197 6.45T290.8 79a199.25 199.25 0 0126.06 96.1q.9 51.53-19.32 103.61t-59.9 101.68q-39.7 49.54-95.68 90.21"
        className="cls-1"
      ></path>
    </svg>
  )
}
