import React from "react"
import TaxBlack from "../common/TaxBlack"
import Fade from "react-reveal"
export default function SlappnaAv({ slappnaAvMedOss }) {
  const { rubrik, text } = slappnaAvMedOss
  return (
    <div id="om-oss" className="bg-secondary">
      <div className="container max-w-4xl ">
        <div className="flex flex-col items-center justify-center text-center space-y-4 md:space-y-8">
          <Fade right duration={6000} distance="100px">
            <h2>{rubrik}</h2>
          </Fade>
          <Fade left duration={3000} distance="100px">
            <div className="wp-content">
              <div dangerouslySetInnerHTML={{ __html: text }} />
            </div>{" "}
          </Fade>
          <div className="w-24 pt-8">
            <TaxBlack />
          </div>
        </div>
      </div>
    </div>
  )
}
