import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Blob from "../common/Blob"

import AcademyLogo from "../common/AcademyLogo"
import WhiteButton from "../common/WhiteButton"

export default function Academy({ academy }) {
  return (
    <div className="md:min-h-screen   bg-primary/80">
      <div className="relative h-full w-full ">
        <div className="relative md:absolute z-20 md:pl-12 md:pt-12 ">
          <div className="w-48 text-black hidden md:block">
            <AcademyLogo />
          </div>
        </div>
        <div className="relative h-full w-full ">
          <StaticImage
            src="../../images/Maneki-miljjo-4.jpg"
            objectFit="cover"
            className="h-full w-full object-cover md:min-h-screen"
            alt="Academy"
          />
        </div>
        <div className="md:absolute top-0 w-full h-full flex flex-col items-center justify-center py-8 md:py-0 ">
          <div className="text-white relative z-10">
            <div className="w-0 md:w-[45rem] relative rotate-90 md:left-48 opacity-80">
              <Blob />
            </div>
            <div className="p-8 pt-0 md:p-0 md:absolute text-center bottom-[16rem] -right-[15.5rem] max-w-[30rem] text-black">
              <div className="space-y-4">
                <div className="wp-content">
                  <div dangerouslySetInnerHTML={{ __html: academy.innehall }} />
                </div>
                <div className="my-4">
                  <a href="mailto:stefan.adebahr@maneki.se">
                    <WhiteButton text="Ansök Nu" />
                  </a>
                </div>
                <p className="text-base px-16">
                  *Maneki Academys utbildningsplan är godkänd av
                  Revisorsinspektionen.
                </p>
              </div>
            </div>
          </div>
          <div className="w-36 md:w-48 text-black block md:hidden  ">
            <AcademyLogo />
          </div>
        </div>
      </div>
    </div>
  )
}
