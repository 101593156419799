import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import SlappnaAv from "../components/sections/SlappnaAv"
import Layout from "../components/layout"
import Hero from "../components/sections/Hero"
import SEO from "../components/seo"
import TryggaHander from "../components/sections/TryggaHander"
import KontaktaOss from "../components/sections/KontaktaOss"
import Triage from "../components/sections/Triage"
import Map from "../components/sections/Map"
import Kontakt from "../components/sections/Kontakt"
import TusenKunder from "../components/sections/TusenKunder"
import Nyhetsbrev from "../components/sections/Nyhetsbrev"
import Academy from "../components/sections/Academy"
import Partners from "../components/sections/Partners"
import Jobb from "../components/sections/Jobb"
import Ocean from "../components/sections/Ocean"
import Footer from "../components/sections/Footer"

export default function IndexPage() {
  const data = useStaticQuery(graphql`
    {
      wpPage(databaseId: { eq: 16 }) {
        title
        jobb {
          beskrivningjobb
          tjanster {
            titel
            information
          }
        }
        slappnaAvMedOss {
          rubrik
          text
        }
        sidfot {
          gdpr {
            localFile {
              publicURL
            }
          }
          ovrigaDokument {
            titelOvrigafilerFil
            fil {
              localFile {
                publicURL
              }
            }
          }
        }
        samarbetspartners {
          partners {
            logga {
              localFile {
                publicURL
              }
            }
            namn
          }
          beskrivning
        }
        tryggaHander {
          rubriktryggahander
          texttryggahander
        }
        kontaktaOssGruppbild {
          gruppbildtext
          knapptext
          gruppbild {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        kontaktaOssGruppbildStrangnas {
          knapptextStrangnas
          gruppbildtextStrangnas
          gruppbildStrangnas {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        academy {
          innehall
        }
        treBlock {
          block {
            titel
            text
            bild {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  `)
  const {
    jobb,
    treBlock,
    samarbetspartners,
    kontaktaOssGruppbild,
    kontaktaOssGruppbildStrangnas,
    academy,
    slappnaAvMedOss,
    sidfot,
    tryggaHander,
  } = data.wpPage

  return (
    <Layout>
      <SEO title="Maneki - Revision, skatt och rådgivning" isHome />
      <div className="md:h-screen ">
        <Hero />
      </div>
      <div>
        <SlappnaAv slappnaAvMedOss={slappnaAvMedOss} />
      </div>
      <div>
        <KontaktaOss kontaktaOssGruppbild={kontaktaOssGruppbild} />
      </div>
      <div>
        <TryggaHander tryggaHander={tryggaHander} />
      </div>

      <div>
        <Triage treBlock={treBlock} />
      </div>
      {/* <div>
        <KontaktaOssStrangnas
          kontaktaOssGruppbildStrangnas={kontaktaOssGruppbildStrangnas}
        />
      </div> */}
      <div>
        <Partners samarbetspartners={samarbetspartners} />
      </div>
      <div>
        <Academy academy={academy} />
      </div>
      <div>
        <Jobb jobb={jobb} />
      </div>
      <div>
        <Ocean />
      </div>

      <div>
        <Kontakt />
      </div>
      <div>
        <Map />
      </div>
      <div>
        <Footer sidfot={sidfot} />
      </div>
    </Layout>
  )
}
