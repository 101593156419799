import React from "react"
import Fade from "react-reveal/Fade"

export default function TryggaHander({ tryggaHander }) {
  const { rubriktryggahander, texttryggahander } = tryggaHander
  return (
    <div id="tjanster" className="bg-secondary py-8 relative">
      <div className="container max-w-7xl ">
        <div className="flex flex-col md:flex-row md:items-center md:justify-center gap-4">
          <Fade>
            <div className="flex-1">
              <h2 className="md:w-72">{rubriktryggahander}</h2>
            </div>
          </Fade>
          <div className="flex-1  space-y-8">
            <Fade cascade right distance="300px">
              <div>
                <div
                  className="trygga wp-content"
                  dangerouslySetInnerHTML={{ __html: texttryggahander }}
                />
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  )
}
