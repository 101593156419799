import React from "react"

import LogoOriginal from "../common/LogoOriginal"
import VideoBackground from "../common/VideoBackground"
import Fade from "react-reveal"
import config from "react-reveal/globals"
export default function Hero() {
  config({ ssrFadeout: true })

  return (
    <div className="relative bg-gray-900">
      <div className="absolute h-full w-full">
        <VideoBackground />
      </div>
      <div className="h-[50vh] md:min-h-screen w-full flex flex-col items-center justify-center z-10 relative">
        <Fade ssrFadeout delay={1000}>
          <div className="text-white w-2/3 md:w-1/2">
            <LogoOriginal />
          </div>
        </Fade>
      </div>
    </div>
  )
}
