import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Disclosure } from "@headlessui/react"
import { ChevronUpIcon } from "@heroicons/react/solid"
export default function Triage({ treBlock }) {
  const blocks = treBlock.block

  return (
    <div className="text-base ">
      <div className=" relative grid md:grid-cols-3 text-white">
        {blocks.map((block, i) => {
          return <Block key={i} block={block} />
        })}
      </div>
    </div>
  )
}

function Block({ block }) {
  const { titel, text, bild } = block
  return (
    <div className="bg-secondary  ">
      <div className="bg-black block md:hidden">
        <Disclosure>
          {({ open }) => (
            <div
              style={{ display: "grid" }}
              className={`${open ? "h-full" : "h-36 "} `}
            >
              {/* You can use a GatsbyImage component if the image is dynamic */}
              <GatsbyImage
                class="opacity-40"
                style={{
                  gridArea: "1/1",
                  // You can set a maximum height for the image, if you wish.
                  // maxHeight: 600,
                }}
                layout="fullWidth"
                // You can optionally force an aspect ratio for the generated image

                // This is a presentational image, so the alt should be an empty string
                alt=""
                // Assisi, Perúgia, Itália by Bernardo Ferrari, via Unsplash
                image={getImage(bild.localFile.childImageSharp)}
                formats={["auto", "webp", "avif"]}
              />
              <div
                style={{
                  // By using the same grid area for both, they are stacked on top of each other
                  gridArea: "1/1",
                  position: "relative",
                  // This centers the other elements inside the hero component
                  placeItems: "center",
                  display: "grid",
                }}
                className="p-4"
              >
                {/* Any content here will be centered in the component */}
                <Disclosure.Button>
                  {" "}
                  <div className="flex flex-row items-center gap-4">
                    <h4 className="text-xl py-4">{titel} </h4>
                    <div className="w-6">
                      <ChevronUpIcon
                        className={`${
                          open
                            ? "transition-all"
                            : "transition-all  transform rotate-180"
                        } `}
                      />
                    </div>
                  </div>
                </Disclosure.Button>
                <Disclosure.Panel>
                  {" "}
                  <div
                    className="wp-content"
                    dangerouslySetInnerHTML={{ __html: text }}
                  />
                </Disclosure.Panel>
              </div>
            </div>
          )}
        </Disclosure>
      </div>

      <div className="bg-third hidden md:block relative group cursor-pointer h-full md:min-h-[100vh]">
        <div className="h-full object-cover">
          <GatsbyImage
            image={getImage(bild.localFile.childImageSharp)}
            className="md:min-h-[100vh] h-full w-full object-cover group-hover:opacity-0 transition-all"
            alt={titel}
          />
        </div>
        <div className="absolute top-0 flex items-center justify-center h-full w-full">
          <h2 className="block group-hover:hidden ">{titel}</h2>
          <div className="hidden group-hover:block text-black">
            <div className="w-11/12 mx-auto space-y-8 ">
              <div>
                <h2 className="text-center underline decoration-black/50 underline-offset-4">
                  {titel}
                </h2>
              </div>
              <div className="wp-content">
                {" "}
                <div className="" dangerouslySetInnerHTML={{ __html: text }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
