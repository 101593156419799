import React from "react"

export default function Ocean() {
  return (
    <div className="h-[40vh] z-0 relative bg-black">
      <section className="video-wrapper h-full w-full overflow-hidden relative cover object-cover ">
        <video
          autoPlay
          preload="none"
          playsInline
          muted
          loop
          className="object-cover max-h-auto inset-0 w-full h-full z-0 relative top-25"
        >
          <source
            src="https://olleburl.in/maneki/wp-content/uploads/2022/03/ocean-comp.mp4"
            type="video/mp4"
          />
        </video>
      </section>
    </div>
  )
}
